import { NotificationToneType, NotificationToneOptions } from "../constants";

export class NotificationToneService {
  private toneCache: Map<string, HTMLAudioElement> = new Map();

  constructor(private defaultNotificationToneOptions: NotificationToneOptions) {
    this.play = this.play.bind(this);
  }

  public play(type: NotificationToneType) {
    const toneName: string | undefined = this.defaultNotificationToneOptions[type];

    let tone = this.toneCache.get(`${toneName}`);
    tone = tone ? tone : this.cacheFail(toneName);
    tone.play();
  }

  private cacheFail(toneName?: string): HTMLAudioElement {
    const tone = new Audio(toneName && `/media/${toneName}.mp3`);
    this.toneCache.set(`${toneName}`, tone);
    return tone;
  }
}
