import {
  Button,
  CircularProgress,
  createMuiTheme,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  MuiThemeProvider
} from "@material-ui/core";
import { cyan } from "@material-ui/core/colors";
import BotChatIcon from "@material-ui/icons/Adb";
import CompleteChatIcon from "@material-ui/icons/SpeakerNotesOff";
import { PeerMessage } from "messaging";
import { inject, observer } from "mobx-react";
import React, { ChangeEvent, Component, createRef, Fragment } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { Link, RouteComponentProps } from "react-router-dom";
import { AppBar, CHILDREN_PROP_TYPE_VARIANT } from "../../components/AppBar";
import { ChatWithUsDialog } from "../../components/ChatWithUsDialog/index";
import { InputElement, INPUT_ELEMENT_VARIANT } from "../../components/InputElement/index";
import { Message, MESSAGE_VARIANT } from "../../components/Message/index";
import { OptionButtons } from "../../components/OptionButtons/index";
import {
  ChatManagerStore,
  ChatStore,
  ContextAndIdStore,
  HotelStore,
  INTERNAL_CHAT_CONTROL_MESSAGES
} from "../../stores/index";
import styled from "../../styles/styled-components";
import { MessagesEnd } from "../MessagingScreen/index";

export interface IMessagingPageState {
  textBoxContent: string;
  showCompleteChatDialog: boolean;
}

const Content = styled.div`
  padding: 16px;
  width: 100%;
`;

const ProgressWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 6px;
`;

const muiTheme = createMuiTheme({
  palette: {
    primary: cyan
  }
});

export interface IMessagingPageProps extends RouteComponentProps<{ tid: string; pid: string }> {
  chatManagerStore: ChatManagerStore;
  chatStore: ChatStore;
  contextAndIdStore: ContextAndIdStore;
  hotelStore: HotelStore;
}

@inject("chatStore", "chatManagerStore", "contextAndIdStore", "hotelStore")
@observer
class MessagingPage extends Component<IMessagingPageProps, IMessagingPageState> {
  public messagesEnd = createRef<HTMLDivElement>();

  constructor(props: IMessagingPageProps) {
    super(props);
    this.state = {
      textBoxContent: "",
      showCompleteChatDialog: false
    };
  }

  public componentDidMount = async () => {
    const { tid, pid } = this.props.match.params;
    const gid = localStorage.getItem(`${tid}/${pid}`) as string | undefined;

    const { chatStore, contextAndIdStore } = this.props;
    contextAndIdStore.setAllIds({ gid, pid, tid });
    this.scrollToBottom();

    chatStore.setOnFirstPageLoad(this.scrollToBottom);
    chatStore.setOnNewMessage(this.scrollToBottom);
  };

  public scrollToBottom = () => {
    if (this.messagesEnd.current) {
      this.messagesEnd.current.scrollIntoView();
    }
  };

  public handleTyping = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ textBoxContent: event.currentTarget.value });
  };

  public handleSendMessage = () => {
    const text = this.state.textBoxContent;
    this.props.chatManagerStore.showReasonInput
      ? this.props.chatManagerStore.updateReason(text)
      : this.props.chatManagerStore.reply(text);
    this.setState({ textBoxContent: "" });
  };

  public handleCompleteChatClick = () => {
    this.setState({ showCompleteChatDialog: true });
  };

  public handleCompleteChatDialogAgree = () => {
    this.props.chatManagerStore.complete();
    this.setState({ showCompleteChatDialog: false });
  };

  public handleCompleteChatDialogDisagree = () => {
    this.setState({ showCompleteChatDialog: false });
  };

  public render() {
    const {
      chatManagerStore,
      chatStore,
      chatStore: { messages, postConnectionMessages, preConnectionMessages },
      contextAndIdStore: { guest },
      hotelStore: { hotel }
    } = this.props;

    const { showCompleteChatDialog } = this.state;

    return (
      <AppBar
        title={"Chat with us"}
        isLocaleShown={false}
        render={() => (
          <Fragment>
            {(this.props.match.url.includes("gcp") || this.props.match.url.includes("website-bot")) && (
              <IconButton
                color="inherit"
                component={({ innerRef, ...props }) => <Link {...props} to={this.props.match.url.split("/chat")[0]} />}
              >
                <BotChatIcon />
              </IconButton>
            )}
            {chatManagerStore.session && (
              <IconButton onClick={this.handleCompleteChatClick} color="inherit" aria-label="Menu">
                <CompleteChatIcon />
              </IconButton>
            )}
          </Fragment>
        )}
        variant={CHILDREN_PROP_TYPE_VARIANT.RENDER_PROP}
      >
        {({ contentContainerRef }) => (
          <Fragment>
            <Dialog
              open={showCompleteChatDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle>Sure you want to end this chat?</DialogTitle>
              <DialogActions>
                <Button onClick={this.handleCompleteChatDialogAgree}>AGREE</Button>
                <Button onClick={this.handleCompleteChatDialogDisagree}>DISAGREE</Button>
              </DialogActions>
            </Dialog>
            <ChatWithUsDialog
              initialchatWithUsFormValues={chatManagerStore.prevChatWithUsFormValues}
              onSubmit={chatManagerStore.start}
              open={chatManagerStore.showChatWithUsDialog}
            />
            <MuiThemeProvider theme={muiTheme}>
              <Content>
                {preConnectionMessages &&
                  preConnectionMessages.length > 0 &&
                  preConnectionMessages.map(
                    (preConnectionMessage: PeerMessage, index: number) =>
                      preConnectionMessage.type === PeerMessage.type && (
                        <Fragment key={`${index}`}>
                          {preConnectionMessage.text !== INTERNAL_CHAT_CONTROL_MESSAGES.INTERNAL_LOADING && (
                            <Message
                              message={preConnectionMessage}
                              previousMessage={preConnectionMessages[index - 1]}
                              logo={hotel && hotel.displayLogo}
                              guest={guest!}
                              index={index}
                              variant={MESSAGE_VARIANT.PEER_MESSAGE}
                            />
                          )}
                          {preConnectionMessage.text === INTERNAL_CHAT_CONTROL_MESSAGES.INTERNAL_LOADING &&
                            index === preConnectionMessages.length - 1 && (
                              <Message
                                message={preConnectionMessage}
                                previousMessage={preConnectionMessages[index - 1]}
                                logo={hotel && hotel.displayLogo}
                                guest={guest!}
                                index={index}
                                variant={MESSAGE_VARIANT.PEER_MESSAGE_WITH_CHILDREN_PROP}
                              >
                                <CircularProgress color="inherit"></CircularProgress>
                              </Message>
                            )}
                          {index === preConnectionMessages.length - 1 &&
                            preConnectionMessage.optionMessage &&
                            preConnectionMessage.optionMessage.options &&
                            preConnectionMessage.optionMessage.options.length > 0 && (
                              <OptionButtons
                                // NOTE: This baseUrl concept for internal routes could be improved @technical-debt @future-improvement
                                baseUrl={this.props.match.url.split("/chat")[0]}
                                optionMessage={preConnectionMessage.optionMessage!}
                                variant="pill"
                                transitionDelay={1000}
                                handleClick={chatManagerStore.internalReply}
                              />
                            )}
                        </Fragment>
                      )
                  )}

                <InfiniteScroll
                  pageStart={0}
                  initialLoad={false}
                  useWindow={false}
                  isReverse
                  threshold={50}
                  loadMore={(page: number) => chatStore.fetch(page)}
                  hasMore={chatStore.hasMoreMessagesToFetch()}
                  loader={
                    <ProgressWrapper key={"infinite-scoll-loader"}>
                      <CircularProgress color="inherit" />
                    </ProgressWrapper>
                  }
                  getScrollParent={() => contentContainerRef}
                >
                  {messages &&
                    messages.length > 0 &&
                    messages.map(
                      (message: PeerMessage, index: number) =>
                        message.type === PeerMessage.type && (
                          <Fragment key={`${index}`}>
                            <Message
                              message={message}
                              previousMessage={messages[index - 1]}
                              guest={guest!}
                              index={index}
                              variant={MESSAGE_VARIANT.STAFF_MESSAGE}
                            />
                          </Fragment>
                        )
                    )}
                </InfiniteScroll>

                {postConnectionMessages &&
                  postConnectionMessages.length > 0 &&
                  postConnectionMessages.map(
                    (postConnectionMessage: PeerMessage, index: number) =>
                      postConnectionMessage.type === PeerMessage.type && (
                        <Fragment key={`${index}`}>
                          {postConnectionMessage.text !== INTERNAL_CHAT_CONTROL_MESSAGES.INTERNAL_LOADING && (
                            <Message
                              message={postConnectionMessage}
                              previousMessage={preConnectionMessages[index - 1]}
                              logo={hotel && hotel.displayLogo}
                              guest={guest!}
                              index={index}
                              variant={MESSAGE_VARIANT.PEER_MESSAGE}
                            />
                          )}
                          {index === postConnectionMessages.length - 1 &&
                            postConnectionMessage.optionMessage &&
                            postConnectionMessage.optionMessage.options &&
                            postConnectionMessage.optionMessage.options.length > 0 && (
                              <OptionButtons
                                // NOTE: This baseUrl concept for internal routes could be improved @technical-debt @future-improvement
                                baseUrl={this.props.match.url.split("/chat")[0]}
                                optionMessage={postConnectionMessage.optionMessage!}
                                variant="pill"
                                transitionDelay={1000}
                                handleClick={chatManagerStore.internalReply}
                              />
                            )}
                        </Fragment>
                      )
                  )}
                {(chatManagerStore.session || chatManagerStore.showReasonInput) && (
                  <InputElement
                    inputElementMessage={{
                      type: "text",
                      placeHolder: "Enter your message here"
                    }}
                    textBoxContent={this.state.textBoxContent}
                    handleTyping={this.handleTyping}
                    handleSendMessage={this.handleSendMessage}
                    textArea={chatManagerStore.showReasonInput}
                    variant={INPUT_ELEMENT_VARIANT.CHAT_FREE_TEXT}
                  />
                )}
                <MessagesEnd ref={this.messagesEnd} />
              </Content>
            </MuiThemeProvider>
          </Fragment>
        )}
      </AppBar>
    );
  }
}

export default MessagingPage;
