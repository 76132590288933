import { action, observable } from "mobx";

export interface ILocaleSelect {
  localeLabel: string;
  localeValue: string;
}

export class LocaleStore {
  @observable public locale: string = "en";
  public localeList: ILocaleSelect[] = [
    {
      localeLabel: "English",
      localeValue: "en"
    },
    {
      localeLabel: "عربى",
      localeValue: "ar"
    },
    {
      localeLabel: "日本人",
      localeValue: "ja"
    },
    {
      localeLabel: "Français(e)",
      localeValue: "fr"
    },
    {
      localeLabel: "中文",
      localeValue: "zh"
    }
  ];

  @action public setLocale = (locale: string) => {
    this.locale = locale;
  };
}
