import {
  AppBar as MuiAppBar,
  createStyles,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  withStyles,
  WithStyles,
  Theme
} from "@material-ui/core";
import TranslateIcon from "@material-ui/icons/GTranslate";
import { inject, observer } from "mobx-react";
import React, { Component, createRef, Fragment, MouseEvent, ReactNode } from "react";
import { ILocaleSelect, LocaleStore, ClientStore } from "../../stores";

const styles = (theme: Theme) =>
  createStyles({
    appBar: {
      backgroundImage: "linear-gradient(to top, #ffd273, #ffde3b)",
      color: "#000000"
    },
    root: {
      display: "flex",
      flexDirection: "column",
      height: "100%"
    },
    offlineToast: {
      backgroundColor: theme.palette.secondary.main,
      color: "white",
      padding: "4px 0 4px 16px"
    },
    content: {
      backgroundImage: `url("/img/bg.png")`,
      backgroundBlendMode: "difference",
      backgroundColor: "#f0f0f0",
      flexGrow: 1,
      overflowY: "auto"
    },
    grow: {
      flexGrow: 1
    },
    menuButton: {
      marginLeft: -12
    }
  });

interface IAppBarProps extends WithStyles<typeof styles> {
  clientStore?: ClientStore;
  isLocaleShown?: boolean;
  localeStore?: LocaleStore;
  render?: (props: {}) => ReactNode;
  title: string;
}

export enum CHILDREN_PROP_TYPE_VARIANT {
  REACT_NODE = "REACT_NODE",
  RENDER_PROP = "RENDER_PROP"
}

type AppBarProps =
  | IAppBarProps & {
      children: ReactNode;
      variant: CHILDREN_PROP_TYPE_VARIANT.REACT_NODE;
    }
  | IAppBarProps & {
      children: (props: { contentContainerRef: HTMLDivElement | null }) => ReactNode;
      variant: CHILDREN_PROP_TYPE_VARIANT.RENDER_PROP;
    };

interface IAppBarState {
  translateAnchorEl: HTMLElement | undefined;
}

@inject("clientStore", "localeStore")
@observer
class AppBar extends Component<AppBarProps, IAppBarState> {
  private contentContainerRef = createRef<HTMLDivElement>();

  public state: IAppBarState = {
    translateAnchorEl: undefined
  };

  public setTranslateAnchorEl = (value: HTMLElement | undefined) => {
    this.setState({ translateAnchorEl: value });
  };

  public render() {
    const { translateAnchorEl } = this.state;
    const { children, classes, clientStore, isLocaleShown, localeStore, render, title } = this.props;
    const { locale, localeList, setLocale } = localeStore!;
    const { isConnectedToMQTTBroker } = clientStore!;
    return (
      <div className={classes.root}>
        <MuiAppBar position="static" className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" color="inherit" className={classes.grow}>
              {title ? title : null}
            </Typography>
            {render && render({})}
            {isLocaleShown && (
              <Fragment>
                <IconButton
                  className={classes.menuButton}
                  color="inherit"
                  aria-label="Translate"
                  aria-haspopup="true"
                  aria-owns={Boolean(translateAnchorEl) ? "translate-menu" : undefined}
                  onClick={(event: MouseEvent<HTMLElement>) => this.setTranslateAnchorEl(event.currentTarget)}
                >
                  <TranslateIcon />
                </IconButton>
                <Menu
                  id="translate-menu"
                  anchorEl={translateAnchorEl}
                  open={Boolean(translateAnchorEl)}
                  onClose={() => this.setTranslateAnchorEl(undefined)}
                >
                  {localeList.map((localeSelect: ILocaleSelect) => (
                    <MenuItem
                      key={`${localeSelect.localeLabel}-${localeSelect.localeValue}`}
                      selected={localeSelect.localeValue === locale}
                      onClick={() => {
                        setLocale(locale);
                        this.setTranslateAnchorEl(undefined);
                      }}
                    >
                      {localeSelect.localeLabel}
                    </MenuItem>
                  ))}
                </Menu>
              </Fragment>
            )}
          </Toolbar>
        </MuiAppBar>
        {!isConnectedToMQTTBroker && (
          <div className={classes.offlineToast}>You're offline! We're trying to reconnect...</div>
        )}
        <div ref={this.contentContainerRef} className={classes.content}>
          {this.props.variant === CHILDREN_PROP_TYPE_VARIANT.RENDER_PROP &&
            this.props.children({ contentContainerRef: this.contentContainerRef.current })}
          {this.props.variant === CHILDREN_PROP_TYPE_VARIANT.REACT_NODE && children}
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(AppBar);
