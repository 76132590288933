"use strict";

function __export(m) {
  for (var p in m) {
    if (!exports.hasOwnProperty(p)) exports[p] = m[p];
  }
}

Object.defineProperty(exports, "__esModule", {
  value: true
});

__export(require("./DepartmentNotification"));

__export(require("./GuestNotification"));

__export(require("./InternalNotification"));

__export(require("./PropertyNotification"));

__export(require("./StaffNotification"));

var pb_1 = require("./pb");

exports.Notification = pb_1.Notification;
exports.NotificationData = pb_1.NotificationData;
exports.ServiceRequest = pb_1.ServiceRequest;
exports.ServiceRequestData = pb_1.ServiceRequestData;
exports.ServiceRequestEscalation = pb_1.ServiceRequestEscalation;
exports.HotelBooking = pb_1.HotelBooking;